import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/Josh.Barnes/josh/c2/denovo/src/templates/homepage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`This is the homepage.`}</h1>
    <p>{`This is the index content, for the site's home page.`}</p>
    <p>{`See also `}<a parentName="p" {...{
        "href": "/blog"
      }}>{`the blog section`}</a></p>
    <p>{`Let's see some `}<a parentName="p" {...{
        "href": "https://hipsum.co/paras=2&type=hipster-centric"
      }}>{`hipsum`}</a></p>
    <p>{`Occupy blog helvetica, biodiesel gluten-free intelligentsia sustainable trust fund pinterest keytar kogi unicorn mumblecore. Man braid scenester four dollar toast blog lyft paleo. Fixie helvetica mumblecore banh mi, green juice tacos listicle actually master cleanse lumbersexual franzen enamel pin freegan hammock flannel. Poke mumblecore DIY shaman, umami hell of kombucha raclette.`}</p>
    <p>{`Selfies tousled twee, gochujang vape succulents actually venmo sriracha hexagon hot chicken cred pug authentic. Mixtape lomo health goth sriracha. +1 poke small batch truffaut prism 90's. Lumbersexual flexitarian hell of mumblecore yuccie sartorial umami chia gochujang hashtag flannel whatever. Health goth truffaut gluten-free, photo booth tofu shaman semiotics venmo chia vape artisan. Gentrify whatever mixtape portland roof party. Disrupt put a bird on it distillery viral subway tile bespoke.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      