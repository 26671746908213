import React from "react"
import { css, Styled } from "theme-ui"

import Helmet from "react-helmet"

import Layout from "../gatsby-theme-blog/components/layout"
// import SEO from "gatsby-theme-blog/src/components/seo"
import PostTitle from "gatsby-theme-blog/src/components/post-title"
import PostHero from "gatsby-theme-blog/src/components/post-hero"

const Page = ({
  children,
  location,
...other}) => {
  // console.log('other', other);
  return <Layout location={location} title={'title'}>

    {/* <SEO
      title='{post.title}'
      description='{post.excerpt}'
      imageSource='{
        post.socialImage
          ? post.socialImage?.childImageSharp?.fluid.src
          : post.image?.childImageSharp?.fluid.src
      }'
      imageAlt='{post.imageAlt}'
    /> */}
    <main>
      <article>
        <header>
          <PostHero post={'post'} />
          <PostTitle>{'post.title'}</PostTitle>

        </header>
        <section>{children}</section>
      </article>
    </main>
  </Layout>
}

// export default Page

export default (props) => (
    <>
    <Page {...props} >{props.children}
    <h1>Properties</h1>
    <pre>
      <code>
      {JSON.stringify(props, censor(props), 2)}
      </code>
    </pre>
    </Page>
    </>
)


function censor(censor) {
    var i = 0;
    return (key, value) => {
      if(i !== 0 && typeof(censor) === 'object' && typeof(value) == 'object' && censor == value)
        return '[Circular]';

      if(i >= 300) // seems to be a harded maximum of 30 serialized objects?
        return '[Unknown]';
      ++i; // so we know we aren't using the original object anymore
      return value;
    }
  }
